import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import PrimeVue from 'primevue/config';
import definePlugin from './plugins/plugins';

import "primevue/resources/themes/viva-light/theme.css";
import "primevue/resources/primevue.min.css";
import '/node_modules/primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import './prime-custom.scss';
import './main.scss';
import 'aos/dist/aos.css'

const app = createApp(App);
definePlugin(app);

app.use(store)
    .use(router)
    .use(PrimeVue)
    .mount("#app");
