<template>
    <Card class="bg-color4 my-6 mx-3">
        <template #content>
            <div class="md:flex">
                <div class="col-12 md:col-7 pl-3">
                    <span class="text-left my-0 py-0 text-6xl font-bold" data-aos="fade-left" data-aos-easing="ease-in-out"
                        data-aos-duration="2000">Let's Connect with us to <br> take your business <br> to next level</span>
                </div>
                <div class="col-12 md:col-5 md:flex align-items-center justify-content-center">
                    <div class="col-12 md:col-6 mb-2 md:mb-0">
                        <Button class="w-full text-2xl md:text-base" icon="pi pi-whatsapp" label="WhatsApp us"
                            style="height: 40px;" data-aos="zoom-in-left" data-aos-easing="ease-in-out"
                            data-aos-duration="2000" />
                    </div>
                    <div class="col-12 md:col-6">
                        <Button class="w-full text-2xl md:text-base" icon="pi pi-phone" label="Call us"
                            style="height: 40px;" data-aos="zoom-in-right" data-aos-easing="ease-in-out"
                            data-aos-duration="2000" />
                    </div>
                </div>
            </div>
        </template>
    </Card>
</template>