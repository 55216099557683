
import OurServices from '@/components/home/OurServices.vue';
import WhoWeAre from '@/components/home/WhoWeAre.vue';
import FooterComp from "@/components/common/FooterComp.vue";
import WhyChooseUs from '@/components/home/WhyChooseUs.vue';
import ConnectWithUs from '@/components/home/ConnectWithUs.vue';
import WriteToUs from '@/components/home/WriteToUs.vue'

export default {
  components: {
    // "fat-banner-main": BannerMain,
    // "fat-who-we-are": WhoWeAre,
    "fat-our-services": OurServices,
    "fat-footer": FooterComp,
    "fat-why-choose-us": WhyChooseUs,
    "fat-connect-with-us": ConnectWithUs,
    "fat-write-to-us": WriteToUs
  }
}
