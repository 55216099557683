
import { ref } from 'vue';
import AOS from 'aos';

export default {
    setup() {
        const isMenuClose = ref(false);
        const menu = ref([{ label: 'Home' },
        { label: 'About' }]);

        const toggle = () => {
            document.getElementById('burger')?.classList.toggle('is-open');
            document.getElementById('main-nav')?.classList.toggle('is-open');
            isMenuClose.value = !isMenuClose.value;
        }

        AOS.init();

        const closeMenu = () => {
            if (isMenuClose.value) {
                toggle();
            }
        };

        return { menu, toggle, isMenuClose, closeMenu };
    }
}
