<template>
    <Card class="bg-color7 my-6 mx-3">
        <template #content>
            <div class="md:flex">
                <div class="col-12 md:col-6 mt-0 pt-0" data-aos="fade-down-right" data-aos-easing="ease-in-out"
                        data-aos-duration="1000">
                    <h3 class="text-left my-0 pb-4 color-blue" data-aos="zoom-out-down" data-aos-easing="ease-in-out"
                        data-aos-duration="3000">Write to us</h3>
                    <InputText placeholder="Name" class="w-full" ></InputText>
                    <InputText placeholder="Mobile Number" class="mt-2 w-full" ></InputText>
                    <InputText placeholder="Email" class="mt-2 w-full" ></InputText>
                    <InputText placeholder="Company Name" class="mt-2 w-full" ></InputText>
                    <Textarea placeholder="Message" class="mt-2 w-full" rows="5" ></Textarea>
                    <Button label="Send" style="height: 40px;" class="mt-2 w-full" severity="secondary" />
                </div>
                <div class="col-12 md:col-6 hidden md:block pl-5">
                    <span class="text-left my-0 py-0 text-6xl font-bold" data-aos="fade-left" data-aos-easing="ease-in-out"
                        data-aos-duration="2000">We're eager to hear from you – drop us a line and let's start crafting your digital success story together</span>
                </div>
                <div class="col-12 md:col-6 block md:hidden ">
                    <span class="text-left my-0 py-0 text-6xl font-bold" data-aos="fade-left" data-aos-easing="ease-in-out"
                        data-aos-duration="2000">Let's Connect with us to <br> take your business <br> to next level</span>
                </div>
            </div>
        </template>
    </Card>
</template>