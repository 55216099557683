import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fat_our_services = _resolveComponent("fat-our-services")!
  const _component_fat_why_choose_us = _resolveComponent("fat-why-choose-us")!
  const _component_fat_connect_with_us = _resolveComponent("fat-connect-with-us")!
  const _component_fat_write_to_us = _resolveComponent("fat-write-to-us")!
  const _component_fat_footer = _resolveComponent("fat-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_fat_our_services),
    _createVNode(_component_fat_why_choose_us),
    _createVNode(_component_fat_connect_with_us),
    _createVNode(_component_fat_write_to_us),
    _createVNode(_component_fat_footer)
  ], 64))
}