<template>
    <div class="footer mt-4 pt-4">
        <div class="md:flex col-12">
            <div class="col-12 md:col-4 text-left pl-8">
                <div class="font-bold text-xl ">
                    Our Services
                </div>
                <ul>
                    <li class="line-height-3">Search Engine Optimization (SEO)</li>
                    <li class="line-height-3">Email Marketing</li>
                    <li class="line-height-3">Lead Generation</li>
                    <li class="line-height-3">Marketing Research</li>
                    <li class="line-height-3">Social Media Marketing</li>
                    <li class="line-height-3">Google Ads</li>
                </ul>
            </div>
            <div class="col-12 md:col-4 text-left pl-8">
                <div class="font-bold text-xl ">
                    Contact us
                </div>
                <ul>

                </ul>
            </div>
            <div class="col-12 md:col-4 text-left pl-8">
                <div class="font-bold text-xl ">
                    Follow us
                </div>
                <ul>

                </ul>
            </div>
        </div>
        <div class="copyright col-12 pb-4">
            © 2023 FAT Web Studio
        </div>
    </div>
</template>

<style lang="css">
ul {
    list-style: none;
    padding-left: 0;
    font-size: 14px
}
</style>